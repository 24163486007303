<template>
    <div
        class="attestation-block h-100 d-flex flex-column" :class="{'attestation-block__inactive': !testing_on}">
        <div class="attestation-block__title">Повторное тестирование</div>
        <div class="attestation-block__hint">
            Если в вашей компании сильно изменились правила, то вы можете провести повторное тестирование
            сотрудников
        </div>
        <div class="attestation-block__buttons mt-auto">
            <a v-if="testing_on" class="testing__btn-settings btn btn-rounded btn-primary-dark" @click="openModal" href="javascript:void(0)">
                <i class="fa fa-cog mr-5"></i> Настроить
            </a>
            <a v-else class="btn btn-rounded btn-alt-secondary reload-ls " href="javascript:void(0)">
                Перезапустить
            </a>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import $ from 'jquery';
    // import '../jquery/popup';
    import {Render} from '../jquery/popup';

    export default {
        name: 'TestRepeatWidget',
        data() {
            return {
            }
        },
        props: {
            testing_on: {
                type: Boolean,
                required: false
            }
        },
        methods: {
            async openModal() {
                try {
                    const request = session.get('/api/v1/testing-repeat-widget/reload_data/');
                    let response = await request;
                    let infoData = response.data.data;

                    let newpopup = `
                    <div class="row employees-selection hidden">
                    <div class="col-md-6">
                        <div class="employees-selection_left" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="input-group employees-selection__search">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                    <i class="fa fa-search"></i>
                                    </span>
                                </div>
                                <input class="form-control" placeholder="Поиск" type="search">
                            </div>
                            <div class="employees-selection__wrapper">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                       <div class="employees-selection_right">
                           <div class="employee-selection__heading">Добавленные</div>
                           <ul class="employees__list"></ul>
                       </div>
                    </div>
                    </div>
                    <div class="selection-settings">
                        <div class="form-radio" v-for="option in options" :key="option.value" >
                                        <input class="v-radio" type="radio" name="allrules" checked value="all" id="id_all">
                                        <label class="v-radio-label" for="id_all">
                                            По всем правилам
                                        </label>
                        </div>
                        <div class="datepicker-row d-flex align-items-center">
                            <div class="form-radio" v-for="option in options" :key="option.value" >
                                            <input class="v-radio" type="radio" name="allrules" value="notall" id="id_notall">
                                            <label class="v-radio-label" for="id_notall">
                                                По изменениям в правилах с
                                            </label>
                            </div>

                            <input type="text" class="js-datepicker datepicker-input form-control js-datepicker-enabled" disabled
                                   id="rulesdatatime" name="rulesdatatime" data-week-start="1" ="" data-autoclose="true"
                            data-today-highlight="true" data-date-format="dd/mm/yy" placeholder="dd/mm/yy">
                        </div>
                    </div>
                    `
                    this.$swal({
                        html: newpopup,
                        title: '<div class="swal2-title-left">Настройка тестирования</div><div class="swal2-title-right"><label for="all" class="custom-checkbox"><input id="all" type="checkbox"><span class="custom-checkbox__text-left">Всех</span><span class="custom-checkbox__text-right">Выборочно</span></label></div>',
                        width: '800px',
                        showCloseButton: true,
                        showLoaderOnConfirm: true,
                        focusConfirm: false,
                        confirmButtonText: 'Перезапустить',
                        buttonsStyling: false,
                        willOpen: () => {
                            Render(infoData);
                            $('input[name="allrules"]').on('change', function () {
                                if ($(this).val() == 'all') {
                                    $('#rulesdatatime').prop('disabled', true)
                                } else {
                                    $('#rulesdatatime').prop('disabled', false)
                                }
                            });
                            // $('#rulesdatatime').bootstrapMaterialDatePicker({
                            //     weekStart: 1,
                            //     time: false,
                            //     format: 'DD MMMM YYYY',
                            //     lang: 'ru',
                            // });
                            $('#all').on('change', function (e) {
                                if ($(this).is(':checked') !== true) {
                                    $('.employees-selection').addClass('hidden');
                                } else {
                                    $('.employees-selection').removeClass('hidden');
                                }
                            });

                            let leftSide = $('.employees-selection_left');
                            let rightSide = $('.employees__list');
                            let selectedEmployees = [];
                        },
                        customClass: {
                            confirmButton: 'btn btn-rounded btn-primary-dark',
                            popup: 'custom-popup swal2-overflow revalidation-popup'
                        },
                        preConfirm: function () {}
                    }).then((result) => {
                        if (result.value) {
                            let
                                ids = [],
                                all_users = $('#all').is(':checked'),
                                data_str = '';

                            $('.employee__checkbox:checked').each(function () {
                                ids.push($(this).val())
                            });

                            ids = ids.join('&ids=');

                            data_str += 'allrules=' + $('input[name="allrules"]:checked').val();
                            data_str += '&rulesdatatime=' + $('input[name="rulesdatatime"]').val();

                            if (ids) data_str += '&ids=' + ids;
                            if (!all_users) data_str += '&all_users=' + '1';

                            // this.$swal({
                            //     title: 'Отправка...',
                            //     icon: 'info',
                            //     showConfirmButton: false,
                            //     allowOutsideClick: false,
                            //     html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            // });

                            session.get(`/api/v1/testing-repeat-widget/reload_testing/?${data_str}`)
                                .then(res => {
                                    if (res.status === 204) {
                                        this.$swal('Ошибка!', 'Вы должны выбрать людей для прохождения тестирования!', 'error');
                                    } else {
                                        //Попап переаттестация запущена
                                        let swal = this.$swal({
                                            showCloseButton: true,
                                            customClass: {
                                                popup: 'custom-popup swal2-overflow revalidation-success',
                                            },
                                            width: '780px',
                                            showCancelButton: false,
                                            showConfirmButton: false,
                                            didOpen: () => {
                                                $('body').click('.js-revalidation-success__close', () => {
                                                    swal.close();
                                                });
                                            },
                                            html: '<div1 class="revalidation-success__inner"><div class="revalidation-success__icon-wrap"><img src="/img/test/revalidation-success.png"></div><div class="revalidation-success__text"><div class="revalidation-success__title">Повторное тестирование запущено</div><div class="revalidation-success__desc">Выбранным сотрудникам отправлены письма с просьбой пройти тестирование</div><button class="btn btn-rounded btn-alt-secondary revalidation-success__close js-revalidation-success__close">Закрыть</button></div></div>',
                                        });
                                    }
                                })
                                .catch((err) => {
                                    this.$swal({
                                        title: 'Ошибка!',
                                        text: 'При обработке запроса произошла ошибка на сервере',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timer: 1400,
                                    });
                                });
                        }
                    })

                } catch (err) {
                    console.error(err)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .testing {
        &__btn-settings {
            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }
    }
</style>
